import React, { useState, useEffect, useRef } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilePen, faCircleNodes, faF } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { askGPT, findDifferences } from './../functions/coreFunctions';
import prompts from './../prompts';

import Textbox from './../components/textbox';
import ChatConversation from './../components/chatConversation';
import ReactJson from '@microlink/react-json-view'
import Markdown from 'react-markdown'
import ExamplesPage from '../components/examples';

import { addMessage } from './../functions/firebase';
import { getKnowledgeBase, analyzeAndUpdateKnowledgeGraph } from '../functions/knowledge';

const KnowledgePage = (props) => {

  library.add(faCircleNodes, faFilePen);

  // These are the initial setup messages between the System and Assistant
  const [messages, setMessages] = useState([
    {"role": "system", "content": prompts.knowledgeConversation.openingSetup},
    {"role": "assistant", "content": prompts.knowledgeConversation.openingMessage}
  ]);

  const [knowledgeGraphUpdate, setKnowledgeGraphUpdate] = useState(null);
  // Thinking state is used to show the loading icon
  const [thinking, setThinking] = useState(false);
  const [consideringKnowledgeBaseUpdate, setConsideringKnowledgeBaseUpdate] = useState(false);
  const [userCanInputMessage, setUserCanInputMessage] = useState(true);
  const [showKBChanges, setShowKBChanges] = useState(false);
  const [activeTab, setActiveTab] = useState('base');

  const user = props.user;

  // State for storing knowledge base data
  const [knowledgeBase, setKnowledgeBase] = React.useState(null);

  // When the page loads, we want to initialize the knowledge base into the chat history 
  // as a system message with the JSON object
  useEffect(() => {
    const initializeKnowledgeBase = async () => {
      var data = await getKnowledgeBase(user.uid);
      setKnowledgeBase(data);

      data = JSON.stringify(data);

      var newCoreMessages = [...messages];
      var knowledgeMessage = {"role": "system", "content": `Here is all the knowledge and information about the user as a JSON object: \n ${data}`};
      // Insert the knowledge message after the opening setup message
      newCoreMessages.splice(1, 0, knowledgeMessage);
      setMessages(newCoreMessages);
    };

    initializeKnowledgeBase();
  }, []);

  // This controls how the system should react to when a new message is added to the message history
  // by either the user or the assistant
  useEffect(() => {
    var newestMessage = messages[messages.length - 1];
    var latestMessages = JSON.parse(JSON.stringify(messages));
    latestMessages.shift()

    // If a user submits a message, then we want to ask GPT for a response
    if(newestMessage.role == 'user') {
        handleUserMessage(latestMessages);
    } else {
      // If the assistant submits a message, then we don't do anything and just wait for the user to respond
    }
    
  }, [messages]);

  useEffect(() => {
    // If the state is thinking or considering a knowledge base update, then set userCanInputMessage to false
    // else set it to true
    if(thinking || consideringKnowledgeBaseUpdate) {
      setUserCanInputMessage(false);
    } else {
      setUserCanInputMessage(true);
    }
  }, [thinking, consideringKnowledgeBaseUpdate]);

  const handleUserMessage = async (relaventMessageHistory) => {
    // Send the whole conversation, including the newest user message, to GPT for a response
    var finalResponseRaw = await askGPT(relaventMessageHistory);
    var finalResponse = { role: "assistant", content: finalResponseRaw.message };

    var newCoreMessages = [...messages, finalResponse];
    setMessages(newCoreMessages);
    setThinking(false);

    // Add the final response to the DB
    addMessage(user.uid, finalResponse);

    // Analyze the response and update the knowledge graph
    var knowledgeBase = await getKnowledgeBase(user.uid);
    knowledgeBase = JSON.stringify(knowledgeBase);

    var latestUserMessage = relaventMessageHistory[relaventMessageHistory.length - 1].content;
    setConsideringKnowledgeBaseUpdate(true);
    var didUpdateKnowledgeGraph = await analyzeAndUpdateKnowledgeGraph(user.uid, latestUserMessage, knowledgeBase);
    setConsideringKnowledgeBaseUpdate(false);
    if(didUpdateKnowledgeGraph) {
        console.log(didUpdateKnowledgeGraph);
        setKnowledgeBase(didUpdateKnowledgeGraph.updatedKnowledgeBase);
        setKnowledgeGraphUpdate(didUpdateKnowledgeGraph.markdown.toString());
    }
  }

  // When a user submits a message, this function is called
  // Given a new user generated message, send it to GPT to get a response
  const handleSubmit = (text) => {
    var userResponse = {"role": "user", "content": text}
    var newCoreMessages = [...messages, userResponse];
    // Add the new message to the DB
    addMessage(user.uid, userResponse);
    setMessages(newCoreMessages);
    setThinking(true);
  }

  // toggles whether or not to show the knowledge graph changes
  const toggleShowKBChange = () => {
    if(showKBChanges) {
      setShowKBChanges(false);
    } else { 
      setShowKBChanges(true);
    }
  }

  const changeTab = (tabName) => {
    setActiveTab(tabName);
  }

  return (
      <div className='page column'>
        <div className='page-top-nav'>
          <h1>Knowledge</h1>
          <div onClick={() => changeTab('base')} className={`top-nav-tab ${activeTab === 'base' ? 'active' : ''}`}>Base</div>
          <div onClick={() => changeTab('examples')} className={`top-nav-tab ${activeTab === 'examples' ? 'active' : ''}`}>Examples</div>
        </div>
        
        {activeTab == 'base' &&
          <section>
            <div className='chatContainer'>
                <div className='chatHeader' style={{ position: 'sticky', top: 0 }}>
                    <h1 style={{ display: 'inline-block' }}>Chat</h1>
                </div>

                <ChatConversation thinking={thinking} messages={messages} />
                <Textbox canEdit={userCanInputMessage} onSubmit={handleSubmit}></Textbox>
            </div>
            <div className='output-area'>
                <h1>Knowledge Base</h1>
                { consideringKnowledgeBaseUpdate ? <span><FontAwesomeIcon icon="fa-solid fa-circle-nodes" spin /> Thinking...</span> : ""}
                {/* <p>Last edited a moment ago</p> */}
                
                { knowledgeGraphUpdate != null ? 
                <div>
                    <p onMouseEnter={toggleShowKBChange} onMouseLeave={toggleShowKBChange}><FontAwesomeIcon icon="fa-solid fa-file-pen" /> Changes made to Knowledge Graph</p>
                    { showKBChanges ? <Markdown>{knowledgeGraphUpdate}</Markdown> : "" }
                </div> : "" }
            
                <div className='json-container'>
                  <ReactJson
                      name={"Knowledge Base"}
                      sortKeys={true}
                      quotesOnKeys={false}
                      displayDataTypes={false}
                      enableClipboard={false}
                      src={knowledgeBase}
                      collapsed={2}
                      indentWidth={5}
                  />
                </div>
            </div>
          </section>
          }

          {activeTab == 'examples' &&
            <ExamplesPage user={user} />
          }

      </div>
  );
}

export default KnowledgePage;
