import React, { useEffect, useState } from 'react';
import { getToDoItems, addToDoItem, updateToDoItem, deleteToDoItem} from './../functions/firebase';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NestedListComponent from './../components/draggableList';

const ToDoPage = (props) => {
    library.add(faBoxArchive);

    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');
    const [editIndex, setEditIndex] = useState(-1);
    const [editText, setEditText] = useState('');
    const [showArchived, setShowArchived] = useState(false);

    useEffect(() => {
        const getToDos = async () => {
            const toDoItems = await getToDoItems(props.user.uid);
            setTodos(toDoItems);
        };

        getToDos();
    }, []);

    const handleAddTodo = async () => {
        if (newTodo.trim() !== '') {
            const newToDoItemID = await addToDoItem(props.user.uid, newTodo);
            setTodos([...todos, { toDoItemName: newTodo, completed: false, id: newToDoItemID, archived: false }]);
            setNewTodo('');
        }
    };

    const handleToggleTodo = async (index, todoID, todoName, currentCompleted) => {
        const updatedTodos = [...todos];
        updatedTodos[index].completed = !updatedTodos[index].completed;
        setTodos(updatedTodos);
        await updateToDoItem(props.user.uid, todoID, todoName, !currentCompleted);
    };

    const handleDeleteTodo = async (index, todoID) => {
        const updatedTodos = [...todos];
        updatedTodos[index].archived = true;
        setTodos(updatedTodos);
        await deleteToDoItem(props.user.uid, todoID);
    };

    const handleEditTodo = (index, isCompleted) => {
        if(!isCompleted){
            setEditIndex(index);
            setEditText(todos[index].toDoItemName);
        }
    };

    const handleSaveTodo = async (toDoID) => {
        console.log(editText)
        await updateToDoItem(props.user.uid, toDoID, editText, false);
        const updatedTodos = [...todos];
        updatedTodos[editIndex].toDoItemName = editText;
        setTodos(updatedTodos);
        setEditIndex(-1);
        setEditText('');
    };

    const handleCancelEdit = () => {
        setEditIndex(-1);
        setEditText('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (editIndex !== -1) {
                handleSaveTodo();
            } else {
                handleAddTodo();
            }
        }
    };

    const handleShowArchived = () => {
        setShowArchived(!showArchived);
    }

    return (
        <div className='page noFlex L-P-M toDoPage'>
            <h1>To Do</h1>
    
            <div className='toDoList'>
                {todos.map((todo, index) => (
                    todo.archived ? "" :
                    <div className='toDoItem' key={index}>
                        {editIndex === index ? (
                            <>
                                <input
                                    type="checkbox"
                                    checked={todo.completed}
                                    disabled
                                />
                                <input
                                    type="text"
                                    value={editText}
                                    onChange={(e) => setEditText(e.target.value)}
                                />
                                <button className='minor-btn' onClick={() => handleSaveTodo(todo.id)}>Save</button>
                                <button className='minor-btn' onClick={handleCancelEdit}>Cancel</button>
                            </>
                        ) : (
                            <>
                                <input
                                    type="checkbox"
                                    checked={todo.completed}
                                    onChange={() => handleToggleTodo(index, todo.id, todo.toDoItemName, todo.completed)}
                                />
                                <span onClick={() => handleEditTodo(index, todo.completed)}>
                                    {todo.toDoItemName}
                                </span>
                                { todo.completed ? <button className='minor-btn' onClick={() => handleDeleteTodo(index, todo.id)}><FontAwesomeIcon icon="fa-box-archive" /></button> : "" }
                            </>
                        )}
                    </div>
                ))}
            </div>
            <input
                type="checkbox"
            />
            <input
                type="text"
                value={newTodo}
                onChange={(e) => setNewTodo(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Add new item"
            />
            <h4><button className='minor-btn' onClick={handleShowArchived}>{showArchived ? "Hide Archive": "Show Archived"}</button></h4>
            { showArchived ? 
            <div className='toDoList'>
                {todos.map((todo, index) => (
                    !todo.archived ? "" :
                    <div className='toDoItem' key={index}>
                        <input
                            type="checkbox"
                            checked={todo.completed}
                        />
                        <span>
                            {todo.toDoItemName}
                        </span>
                    </div>
                ))}
            </div>
            : ""}
            {/* <button onClick={handleAddTodo}>Add</button> */}
            {/* <NestedListComponent /> */}
        </div>
    );
};

export default ToDoPage;

