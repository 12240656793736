import { saveDocument, getDocuments } from './../functions/firebase';


// General function to get a response from GPT
  // input: an array of messages, where each message is a message obj
  // output: the data obj response from GPT
const askGPT = async (chatHistory) => {
    const response = await fetch('https://chatgpt-test-production.up.railway.app/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "messages": chatHistory
        })
      });
    
      const data = await response.json();
      return data;
}

const askGPTJSON = async (chatHistory) => {
  const response = await fetch('https://chatgpt-test-production.up.railway.app/askJSON', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "messages": chatHistory
      })
    });
  
    const data = await response.json();
    return data;
}

// General function to compress a chat history into a string
const compressChatHistoryIntoString = (chatHistory) => {
    var chatHistoryString = '';
    for (var i = 0; i < chatHistory.length; i++) {
        var role = chatHistory[i].role;
        if(role == 'user'){
            role = "Justin (Product Manager)"
        } else {
            role = "PM Assistant Writer"
        }
        chatHistoryString += role + ": " + chatHistory[i].content + '\n';
    }
    return chatHistoryString;
}

function findDifferences(original, modified) {
  let differences = {
      changes: {
          edits: {},
          removals: {},
          additions: {}
      },
      markdown: []
  };

  function addMarkdownChange(type, key, originalValue, modifiedValue) {
    let mdChange = '';
    if (type === 'removed') {
        mdChange = `**Removed** \`${key}\`: \`${JSON.stringify(originalValue, null, 2)}\``;
    } else if (type === 'added') {
        mdChange = `**Added** \`${key}\`: \`${JSON.stringify(modifiedValue, null, 2)}\``;
    } else if (type === 'edited') {
        mdChange = `**Edited** \`${key}\`: from \`${JSON.stringify(originalValue, null, 2)}\` to \`${JSON.stringify(modifiedValue, null, 2)}\``;
    }
    differences.markdown.push(mdChange);
}

  function compareUnorderedArrays(arr1, arr2) {
      let arrDifferences = {
          edits: [],
          removals: [],
          additions: []
      };

      let usedIndexes = new Set();

      arr1.forEach(item1 => {
          let found = false;

          for (let i = 0; i < arr2.length; i++) {
              if (usedIndexes.has(i)) continue;

              if (JSON.stringify(item1) === JSON.stringify(arr2[i])) {
                  usedIndexes.add(i);
                  found = true;
                  break;
              }
          }

          if (!found) {
              arrDifferences.removals.push(item1);
              addMarkdownChange('removed', '[array item]', item1, null);
          }
      });

      arr2.forEach((item2, index) => {
          if (!usedIndexes.has(index)) {
              arrDifferences.additions.push(item2);
              addMarkdownChange('added', '[array item]', null, item2);
          }
      });

      return arrDifferences;
  }

  function processChange(key, originalValue, modifiedValue) {
      if (Array.isArray(originalValue) && Array.isArray(modifiedValue)) {
          // Handle array differences
          const arrayDifferences = compareUnorderedArrays(originalValue, modifiedValue);
          if (arrayDifferences.removals.length > 0 || arrayDifferences.additions.length > 0) {
              differences.changes.edits[key] = arrayDifferences;
          }
      } else if (originalValue && modifiedValue && typeof originalValue === 'object' && typeof modifiedValue === 'object') {
          // If both values are objects, do a deep comparison
          const nestedDifferences = findDifferences(originalValue, modifiedValue);
          if (nestedDifferences.markdown.length > 0) {
              differences.changes.edits[key] = nestedDifferences.changes;
              addMarkdownChange('edited', key, 'see nested changes', 'see nested changes');
          }
      } else if (originalValue !== modifiedValue) {
          // If values are different and not both objects, they are simple edits
          differences.changes.edits[key] = { original: originalValue, modified: modifiedValue };
          addMarkdownChange('edited', key, originalValue, modifiedValue);
      }
  }

  // Compare properties from original to modified
  Object.keys(original || {}).forEach(key => {
      if (!(modified || {}).hasOwnProperty(key)) {
          differences.changes.removals[key] = original[key];
          addMarkdownChange('removed', key, original[key], null);
      } else {
          processChange(key, original[key], modified[key]);
      }
  });

  // Compare properties from modified to original
  Object.keys(modified || {}).forEach(key => {
      if (!(original || {}).hasOwnProperty(key)) {
          differences.changes.additions[key] = modified[key];
          addMarkdownChange('added', key, null, modified[key]);
      }
  });

  // Clean up empty categories
  Object.keys(differences.changes).forEach(category => {
      if (Object.keys(differences.changes[category]).length === 0) {
          delete differences.changes[category];
      }
  });

  return differences;
}

const trySaveNewDocument = (userID, writingProjectID, rawResponse) => {
    // turn raw response into JSON object
    console.log(rawResponse);
    var documentContent = JSON.parse(rawResponse)['written-output'];
    if(documentContent != ""){
        // save document to firebase
        console.log('saving document');
        saveDocument(userID, writingProjectID, documentContent);
    }
    
}

// Plays the success chime. Optionally, it can evaluate if an assistant message has
// written content and if it does, then it will play. Otherwise it will be silent.
const playSuccessChime = (assistantMessage) => {
    const chime = new Audio('/success-chime.wav');
    if(assistantMessage != null){
        var assistantMessageJSON = JSON.parse(assistantMessage);
        var writtenOutput = assistantMessageJSON['written-output'];
        if(writtenOutput != ""){
            chime.play();
        }
    } else {
        chime.play();
    }
}



export {playSuccessChime, askGPT, askGPTJSON, compressChatHistoryIntoString, findDifferences, trySaveNewDocument};