import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faOtter, faBrain, faComments, faFileLines, faListCheck, faBookOpen, faChevronRight, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addMessage, newWritingProject, saveDocument, getWritingProjects, logout } from './functions/firebase';

import './App.css';
import './styles/examples.css';
import './styles/messaging.css';

import ChatPage from './pages/chatPage';
import KnowledgePage from './pages/knowledgePage';
import ToDoPage from './pages/toDoPage';
import WritingsPage from './pages/writingsPage';

const Core = (props) => {

  library.add(faOtter, faBrain, faComments, faFileLines, faListCheck, faBookOpen, faChevronRight, faTrashCan);

  const [activeTab, setActiveTab] = useState('Knowledge'); // Default tab
  const [listOfWritingProjects, setListOfWritingProjects] = useState([]); // Default tab
  const [activeWritingProject, setActiveWritingProject] = useState(null); // Default tab

  const changeTab = (tabName) => {
    setActiveTab(tabName);
  }

  const user = props.user;

  useEffect(() => {
    const getWPs = async () => {
      var list = await getWritingProjects(user.uid);
      setListOfWritingProjects(list);
      if(list.length > 0){
        setActiveWritingProject(list[0]);
      }
    }

    getWPs();
  },[]);

  const refreshListOfWritingProjects = async () => {
    var list = await getWritingProjects(user.uid);
    setListOfWritingProjects(list);
  }

  const changeActiveWritingProject = (writingProject) => {
    setActiveWritingProject(writingProject);
    setActiveTab('Write');
  }

  const initNewProject = async () => {
    const newWP = await newWritingProject(user.uid);
    var newList = [...listOfWritingProjects];
    newList.unshift(newWP);
    console.log(newList);
    console.log(newWP);
    setListOfWritingProjects(newList);
    setActiveWritingProject(newWP);
    setActiveTab('Write');
  }

  return (
    <div className="App">
      <div className='v-navbar'>
        <div className='logo'>Kai</div>
        {/* <div className={`nav-tab`} onClick={() => changeTab('Write')}><FontAwesomeIcon icon="fa-solid fa-comments" /> Write</div> */}
        {/* <div className={`nav-tab ${activeTab === 'Writings' ? 'active' : ''}`} onClick={() => changeTab('Writings')}><FontAwesomeIcon icon="fa-solid fa-file-lines" /> Writings</div> */}
        <div className={`nav-tab ${activeTab === 'To Do' ? 'active' : ''}`} onClick={() => changeTab('To Do')}><FontAwesomeIcon icon="fa-solid fa-list-check" /> To Do</div>
        <div className={`nav-tab ${activeTab === 'Knowledge' ? 'active' : ''}`} onClick={() => changeTab('Knowledge')}><FontAwesomeIcon icon="fa-solid fa-brain" /> Knowledge</div>
        {/* <div className={`nav-tab ${activeTab === 'Writing' ? 'active' : ''}`} onClick={() => changeTab('Writing')}><FontAwesomeIcon icon="fa-solid fa-book-open" /> Writing</div> */}
        <div className={`nav-tab`} onClick={initNewProject}><FontAwesomeIcon icon="fa-solid fa-comments" /> New Writing</div>
        <div className='line-below'></div>
        {listOfWritingProjects && listOfWritingProjects.map((writingProject, index) => (
          <div className={`nav-tab ${writingProject.id === activeWritingProject.id && activeTab == "Write" ? 'active' : ''}`} key={index} onClick={() => changeActiveWritingProject(writingProject)}>{writingProject.title}</div>
          ))
        }
      </div>
      <button className='logout' onClick={logout}>Logout : {user.uid.substring(0,5)} v1.2.0</button>
      
      {/* <div className='navbar'>
        <div className='logo'><FontAwesomeIcon icon="fa-otter" /> Moshi</div>
        <div className={`nav-tab ${activeTab === 'Write' ? 'active' : ''}`} onClick={() => changeTab('Write')}><FontAwesomeIcon icon="fa-solid fa-comments" /></div>
        <div className={`nav-tab ${activeTab === 'Writings' ? 'active' : ''}`} onClick={() => changeTab('Writings')}><FontAwesomeIcon icon="fa-solid fa-file-lines" /></div>
        <div className={`nav-tab ${activeTab === 'Knowledge' ? 'active' : ''}`} onClick={() => changeTab('Knowledge')}><FontAwesomeIcon icon="fa-solid fa-brain" /></div>
      </div> */}
     
      {activeTab === 'Write' && (
        // Content for "Write" tab
        <ChatPage
          user={props.user}
          activeWritingProject={activeWritingProject}
          refreshListOfWritingProjects={refreshListOfWritingProjects}
        />
      )}
      {activeTab === 'To Do' && (
        // Content for "Writings" tab
        <ToDoPage user={props.user} />
      )}
      {activeTab === 'Knowledge' && (
        // Content for "Knowledge" tab
        <KnowledgePage user={props.user} />
      )}
      {activeTab === 'Writing' && (
        // Content for "Writing" tab
        <WritingsPage user={props.user} listOfWritingProjects={listOfWritingProjects} />
      )}
      
    </div>
  );
}

export default Core;
